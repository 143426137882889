import React, { useState, useEffect } from 'react';

import fetchJsonp from 'fetch-jsonp';

import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';

import { useCookies } from 'react-cookie';
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics';
import { useTracking } from 'react-tracking';

require('es6-promise').polyfill();

const JobAlertsPage = ({ pageContext: { config }, location, smartfeedID }) => {
  const siteConfig = config;

  const [apiKey, setApiKey] = useState(
    smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID
  );
  const [gdprKey, setGdprKey] = useState(
    smartfeedID ||
      siteConfig.gdprKey ||
      siteConfig.apiKey ||
      siteConfig.smartfeedID
  );
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group ? 'group' : 'id'
  );
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [optinID, setOptinID] = useState(null);
  const [optinStatus, setOptinStatus] = useState('initial');

  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );

  const { trackEvent } = useTracking(
    {
      page: 'JobAlertsPage_OptIn',
      location: location,
      apiKey: siteConfig.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies['ap-signals-user-id'],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      location,
      cookies['ap-signals-user-id'],
      sessionIDValue,
      siteConfig
    );
  }, []);

  useEffect(() => {
    if (apiKey) {
      let searchParams = new URLSearchParams(location.search);
      let entries = searchParams.entries();

      for (let pair of entries) {
        if (pair[0] === 'OptInID') {
          setOptinID(pair[1]);
        }
      }

      let companyDetailsEndpoint = 'GetCompanyDetails';

      if (groupOrIdParam === 'group') {
        companyDetailsEndpoint = 'GetGroupDetails';
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            '/CareerPage/' +
            companyDetailsEndpoint +
            '?id=' +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                '/CareerPage/' +
                'GetApplicantGetwayURL' +
                '?companyId' +
                '=' +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, 'error');
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, 'error');
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError('No API key was provided.');
    }
  }, [apiKey, groupOrIdParam]);

  function optIntoJobAlerts() {
    var dataObjOptIn = {
      JobAlertId: optinID,
    };

    const dataObjQueryString = Object.keys(dataObjOptIn)
      .map((key) => key + '=' + dataObjOptIn[key])
      .join('&');

    fetchJsonp(
      siteConfig.sfAPIEndpoint +
        'VFMVC/' +
        'CareerPage/' +
        'OptInToJobAlerts?' +
        dataObjQueryString,
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonList) {
        if (jsonList != 'Error') {
          trackEvent({
            eventCategory: 'JobAlertsPage',
            eventAction: 'optedIn',
          });
          setVacanciesRouteLoading(false);
          setOptinStatus('Success');
        } else {
          setVacanciesRouteLoading(false);
          setOptinStatus('Error');
        }
      })
      .catch(function (ex) {
        setOptinStatus('Error');
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, 'error');
      });
  }

  return (
    <Layout
      location={location}
      apiKey={apiKey}
      jobAlertsPage={true}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      title="Opt in for Job Alerts"
    >
      {smartfeedCompanyData &&
      !vacanciesRouteLoading &&
      !vacanciesRouteError ? (
        <>
          <div className="wrapper wrapper--not-mobile">
            <section
              className="page-hero vacs-jobalerts-hero"
              style={{
                backgroundImage:
                  'url("https://oaklandcare.com/app/uploads/2019/04/shutterstock_469949942.original-scaled.jpg")',
                // backgroundImage: `url(${contentDataData[1].children[0].children[0][0].value})`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="u-opacity-layer"></div>
              <div className="page-hero__inner">
                <div className="page-hero__content">
                  {optinID ? (
                    <>
                      <h1>Opt in for Job Alerts</h1>
                      <p>Click the button below to opt in for Job Alerts</p>
                    </>
                  ) : (
                    <h1>
                      An opt in ID is required in order to opt in for Job Alerts
                    </h1>
                  )}
                </div>
              </div>
            </section>
          </div>

          <div className="jobalerts-landing wrapper">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <div className="smartfeed-spacing">
                    {smartfeedCompanyData.JobAlertCompanySettings && optinID ? (
                      <div className="col-12 col-lg-8 job-alerts-panel">
                        {optinStatus === 'initial' ? (
                          <button className="nav-action-sm">
                            Opt in for Job Alerts
                          </button>
                        ) : optinStatus === 'Success' ? (
                          <strong>
                            You have successfully opted in for Job Alerts
                          </strong>
                        ) : optinStatus === 'Error' ? (
                          <strong>An unknown error has occured</strong>
                        ) : (
                          <strong>Unkown Optin Status</strong>
                        )}
                      </div>
                    ) : (
                      <p>
                        Sorry, we could not load the job alerts settings
                        required for this form.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>Please wait while we load the job alerts optin form</p>
      )}
    </Layout>
  );
};

export default JobAlertsPage;
